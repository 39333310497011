import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DctSvgIcon from '../Svg';
import Link from '../Link';
import useStyles from './Button.style';

const ButtonMUI = props => {
  const {
    className,
    children,
    iconOnly,
    size,
    iconType,
    buttonType,
    srText,
    field,
    buttonIcon,
    hasBorder,
    type,
    iconName,
    endIcon,
    isStartIcon = false,
    ...other
  } = props;
  const classes = useStyles(props);

  const buttonVariant = (
    <Button
      classes={{
        root: `${classes.root}  ${classes[buttonType]} ${className} ${
          hasBorder ? 'btnWithBorder' : ''
        }`,
      }}
      variant={buttonType === 'form' ? 'contained' : 'text'}
      size={size}
      endIcon={
        !isStartIcon && (
          <Icon>
            <DctSvgIcon name={iconName ? iconName : endIcon} />
          </Icon>
        )
      }
      startIcon={
        isStartIcon && (
          <Icon>
            <DctSvgIcon name={iconName ? iconName : 'ArrowForwardSharpIcon'} />
          </Icon>
        )
      }
      type={type ? type : 'button'}
      color={buttonType}
      aria-label={srText || children || get(field, 'value.text', '')}
      tabIndex={!isEmpty(field) && get(field, 'value.href', '') ? '-1' : '0'}
      {...other}
    >
      {buttonIcon} {children || get(field, 'value.text')}
    </Button>
  );

  if (iconOnly) {
    return (
      <IconButton
        classes={{
          root: `${classes[iconType]} ${className}`,
        }}
        aria-label={srText}
        size={size}
        {...other}
      >
        {children}
      </IconButton>
    );
  }

  if (!isEmpty(field) && get(field, 'value.href', '')) {
    return (
      <Link field={field} srText={get(field, 'value.text', '')}>
        {buttonVariant}
      </Link>
    );
  }

  return buttonVariant;
};

ButtonMUI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  iconOnly: PropTypes.bool,
  iconType: PropTypes.string,
  size: PropTypes.string,
  buttonType: PropTypes.string,
  srText: PropTypes.string,
  hasBorder: PropTypes.bool,
  iconName: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.object,
  }),
  endIcon: PropTypes.string,
  themeColor: PropTypes.string,
};

ButtonMUI.defaultProps = {
  className: '',
  children: null,
  iconOnly: false,
  hasBorder: true,
  iconType: '',
  size: 'medium',
  buttonType: 'primary',
  srText: '',
  iconName: '',
  field: {
    value: {},
  },
  endIcon: 'ArrowForwardSharpIcon',
  themeColor: '',
};

export default ButtonMUI;
