import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import Icon from '@material-ui/core/Icon';
import LinkMUI from '@material-ui/core/Link';

import DctSvgIcon from '../Svg';
import useStyles from './style';

const CustomLink = ({
  className,
  children,
  link,
  hoverColor,
  disableColor,
  isDisabled = false,
  isCustom = false,
  isExternalLink = false,
  customHref = '',
  type = '', //to convert the link to look like form button, pass type='button' | both custom link and Mui link
  iconName,
  srText,
  ...other
}) => {
  const classes = useStyles({ hoverColor, disableColor });
  const isEditor = isExperienceEditorActive();

  return isCustom ? (
    <LinkMUI
      href={customHref}
      rel={isExternalLink ? 'noopener noreferrer' : ''}
      className={clsx(
        'muilink',
        classes.root,
        className,
        isDisabled && 'isDisabled',
        type === 'button' && classes.buttonLink
      )}
      {...other}
    >
      {children}
      {type === 'button' && (
        <Icon>
          <DctSvgIcon name={iconName ? iconName : 'ArrowForwardSharpIcon'} />
        </Icon>
      )}
    </LinkMUI>
  ) : (
    <Link
      className={clsx(
        'sitecorelink',
        classes.root,
        className,
        isDisabled && 'isDisabled',
        type === 'button' && classes.buttonLink
      )}
      field={link}
      {...other}
      aria-label={(srText && srText) || get(link, 'value.text', '')}
    >
      {children}
      {type === 'button' && (
        <Icon>
          <DctSvgIcon name={iconName ? iconName : 'ArrowForwardSharpIcon'} />
        </Icon>
      )}
    </Link>
  );
};

CustomLink.propTypes = {
  className: PropTypes.string,
  srText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  link: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string,
      class: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
    }),
  }),
};

CustomLink.defaultProps = {
  className: '',
};

export default CustomLink;
