import * as actionTypes from './actionTypes';
/**
 *  Initial State filteredData Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
  payload: {},
};
/**
 * On Get filteredData Start Action
 */
const getFilterdDataStart = state => {
  return { ...state, error: null, loading: true, payload: {} };
};

/**
 * On Get filteredData Success Action
 */
const getFilterdDataSuccess = (state, action) => {
  return {
    ...state,
    data: { ...action.payload.results },
    error: null,
    loading: false,
    payload: { ...action.payload.payload },
  };
};

/**
 * On Get filteredData Fail Action
 */
const getFilterdDataFail = (state, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    message: action.message,
    payload: {},
  };
};

/**
 * On Get filteredData Clear Action
 */
const getFilterdDataClear = state => {
  return {
    ...state,
    error: false,
    loading: false,
    data: {},
    payload: {},
  };
};

/**
 * Update filtersList Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERD_DATA_START:
      return getFilterdDataStart(state);
    case actionTypes.GET_FILTERD_DATA_SUCCESS:
      return getFilterdDataSuccess(state, action);
    case actionTypes.GET_FILTERD_DATA_FAIL:
      return getFilterdDataFail(state, action);
    case actionTypes.CLEAR_FILTERD_DATA:
      return getFilterdDataClear(state, action);
    default:
      return state;
  }
};
