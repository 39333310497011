export default [
  'none',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  '0 4px 16px 0 rgba(0,0,0,0.08)',
  'inset 9px 0px 5px -5px rgb(211,211,211)',
  '0px 2px 20px 0px rgba(0, 0, 0, 0.03)',
  '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  '0px 8px 28px 0px rgba(15, 28, 24, 0.1)',
  '0px 4px 15px rgba(0, 0, 0, 0.05)',
  '0px 0px 50px 0px rgba(0, 0, 0, 0.3)',
];