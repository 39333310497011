import { fontFamilyHE as fontFamily } from './font';
import { desktopMedia, pxToRem } from './constants';

export const variantItemsHE = {
  h1: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(42),
    lineHeight: pxToRem(54),
    letterSpacing: '0',
    marginRight: pxToRem(-8),
    [desktopMedia]: {
      fontSize: pxToRem(118),
      lineHeight: pxToRem(142),
      marginRight: pxToRem(-16),
    },
  },
  h2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(44),
    letterSpacing: '0',
    marginRight: pxToRem(-8),
    [desktopMedia]: {
      fontSize: pxToRem(72),
      lineHeight: pxToRem(96),
      marginRight: pxToRem(-16),
    },
  },
  h3: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(45),
    },
  },
  h4: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(26),
    lineHeight: pxToRem(30),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(48),
      lineHeight: pxToRem(60),
    },
  },
  h5: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(19),
    lineHeight: pxToRem(27),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(36),
    },
  },
  h5Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(19),
    lineHeight: pxToRem(27),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(36),
    },
  },
  body1: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(22),
      lineHeight: pxToRem(30),
    },
  },
  body1Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(22),
      lineHeight: pxToRem(30),
    },
  },
  body2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  body2Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
  },
  body3: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    letterSpacing: '0',
  },
  body3Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
  },
  labelLight: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  labelBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  labelBlack: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  labelRegular: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  subTitle: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(30),
    letterSpacing: '0',
  },
  subTitleBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(30),
    letterSpacing: '0',
  },
  cardTitle1: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(45),
    },
  },
  cardTitle2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(19),
    lineHeight: pxToRem(27),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(28),
      lineHeight: pxToRem(40),
    },
  },
  gutterBottom: {
    marginBottom: pxToRem(24),
  },
};
