import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { styles } from './Picture.style';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const useStyles = makeStyles(styles);

const viewportSizes = {
  smallMaxWidth: 768,
  mediumMaxWidth: 1024,
  largeMaxWidth: 1680,
};

gsap.registerPlugin(ScrollTrigger);

const Picture = props => {
  const classes = useStyles();
  const isEditor = isExperienceEditorActive();
  const imgWrapper = useRef(null);
  const paralaxImgWrapper = useRef(null);
  const hasParalax = props.hasOwnProperty('isParalax') ? props.isParalax : true;
  const {
    image = {},
    mobileImage = {},
    minWidth = '1024',
    renditionNeeded = true,
    aspectRatio = {},
    disableLazyLoad = false,
    noRetina = false,
    fallbackBackgroundNeeded = true,
  } = props.media;
  const [error, setError] = useState(false);
  const alternateAltText = get(props, 'alternateAltText', '');
  const aspectRatioMobile = get(aspectRatio, 'mobile', null);
  const aspectRatioDesktop = get(aspectRatio, 'desktop', aspectRatioMobile);

  useEffect(() => {
    // console.log('imgWrapper.find', imgWrapper.find('img'));
    if (imgWrapper.current && hasParalax) {
      setTimeout(() => {
        const imgParalaxTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: paralaxImgWrapper.current,
            start: `${window.innerHeight * 0.17}px bottom`,
            end: '90% top',
            // start: "top bottom", // the default values
            // end: "bottom top",
            scrub: true,
          },
        });
        gsap.set(imgWrapper.current, {
          xPercent: -10,
          yPercent: 0,
        });
        imgParalaxTimeline.fromTo(
          imgWrapper.current,
          {
            yPercent: 0,
            ease: 'none',
          },
          {
            yPercent: -16.6,
          }
        );
      }, 1000);
    }
  }, []);

  if (isEmpty(image)) {
    return null;
  }
  if (isEditor) {
    return (
      <div className={`${classes.root} ${props.className}`}>
        <div
          className={`img-to-animate-wrapper img-in-exp-editor ${classes.img} ${
            classes.paralaxImgWrapper
          } ${hasParalax ? 'has-paralax' : ''}`}
          ref={paralaxImgWrapper}
          style={{ height: '100%' }}
        >
          <div className="img-to-animate" ref={imgWrapper}>
            <Image field={image} data-locator="imageIn_EE" />
          </div>
        </div>
      </div>
    );
  }

  //Lazy loading of images
  if (typeof window !== 'undefined' && process.browser) {
    if (isEditor) {
      require('lazysizes');
    } else {
      require('lazysizes').cfg.preloadAfterLoad = true;
    }
    require('picturefill');
  }

  // This is used when simple <img> tag is needed without media queries like logo img.
  if (!renditionNeeded) {
    const imgProps = {
      'data-locator': 'img-tag-using-isNative',
      className: clsx(
        classes.img,
        'lazyload',
        error && classes.fallback,
        props.className,
        !renditionNeeded && 'noRendition',
        !disableLazyLoad && 'disabledLazyLoad',
        fallbackBackgroundNeeded && classes.fallbackBackground
      ),
      alt: get(image, 'value.alt', '') || alternateAltText,
      'data-src': get(image, 'value.src', null),
      onError: () => setError(true),
      onClick: props.onClick,
      'data-sequence': props.sequence,
    };
    if (!disableLazyLoad) imgProps.src = get(image, 'value.src', null);

    return (
      <div
        className={`img-to-animate-wrapper ${classes.paralaxImgWrapper} ${
          hasParalax ? 'has-paralax' : ''
        }`}
        ref={paralaxImgWrapper}
      >
        <div className="img-to-animate" ref={imgWrapper}>
          <img {...imgProps} />
        </div>
      </div>
    );
  }

  let advancedImage = {};
  const { value = {} } = image;
  const { value: mobileValue = {} } = mobileImage;
  if (!value) {
    return null;
  }
  const isAdvanced =
    value[aspectRatio.desktop] && !!value[aspectRatio.desktop].large;
  const hasMobileAdvancedImage =
    mobileValue[aspectRatio.mobile] && !!mobileValue[aspectRatio.mobile].small;
  if (hasMobileAdvancedImage) {
    advancedImage = {
      small:
        (mobileValue[aspectRatio.mobile] &&
          mobileValue[aspectRatio.mobile].small) ||
        value[aspectRatio.desktop].small,
      small2x:
        (mobileValue[aspectRatio.mobile] &&
          mobileValue[aspectRatio.mobile].small2x) ||
        mobileValue[aspectRatio.desktop].small2x,
    };
  }

  if (isAdvanced) {
    advancedImage = {
      src: value.src,
      alt: value.alt || alternateAltText,
      small:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].small) ||
        value[aspectRatio.desktop].small,
      small2x:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].small2x) ||
        value[aspectRatio.desktop].small2x,
      medium:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].medium) ||
        value[aspectRatio.desktop].medium,
      medium2x:
        (value[aspectRatio.mobile] && value[aspectRatio.mobile].medium2x) ||
        value[aspectRatio.desktop].medium2x,
      large: value[aspectRatio.desktop] && value[aspectRatio.desktop].large,
      large2x: value[aspectRatio.desktop] && value[aspectRatio.desktop].large2x,
      xLarge: value[aspectRatio.desktop] && value[aspectRatio.desktop].xLarge,
      xLarge2x:
        value[aspectRatio.desktop] && value[aspectRatio.desktop].xLarge2x,
      ...advancedImage,
    };
  }

  const {
    src = '',
    alt = '',
    small = '',
    small2x = '',
    medium = '',
    medium2x = '',
    large = '',
    large2x = '',
    xLarge = '',
    xLarge2x = '',
  } = advancedImage;

  return (
    <>
      {/* This is DEFAULT (current) and will get removed when advance module changes are done for all images */}
      {!isAdvanced && renditionNeeded && (
        <div
          className={`img-to-animate-wrapper ${classes.paralaxImgWrapper} ${
            hasParalax ? 'has-paralax' : ''
          }`}
          ref={paralaxImgWrapper}
        >
          <div
            className={`img-to-animate ${clsx(classes.root, props.className)}`}
            ref={imgWrapper}
          >
            <picture data-locator="picture-tag-old">
              {!error && get(mobileImage, 'value.src', null) && (
                <source
                  data-srcset={`${get(mobileImage, 'value.src')}`}
                  media={`(max-width: ${minWidth - 0.01}px)`}
                />
              )}
              {!error && get(image, 'value.src', null) && (
                <source
                  data-srcset={`${get(image, 'value.src')}`}
                  media={`(min-width: ${minWidth}px)`}
                />
              )}
              <img
                className={clsx(
                  classes.img,
                  'lazyload',
                  error && classes.fallback,
                  classes.fallbackBackground
                )}
                alt={get(image, 'value.alt', '') || alternateAltText}
                data-src={get(image, 'value.src', null)}
                onError={() => setError(true)}
                data-locator={props.dataLocator}
                onClick={props.onClick}
              />
            </picture>
          </div>
        </div>
      )}

      {/*  Using <picture> , When Advance Image module data is provided */}
      {isAdvanced && !disableLazyLoad && !noRetina && (
        <div
          className={`img-to-animate-wrapper ${classes.paralaxImgWrapper} ${
            hasParalax ? 'has-paralax' : ''
          } ${clsx(
            classes.root,
            aspectRatioMobile &&
              classes[`aspectRatio--m__${aspectRatioMobile}`],
            aspectRatioDesktop &&
              classes[`aspectRatio--d__${aspectRatioDesktop}`],
            `mobile_${aspectRatioMobile}`,
            `desktop_${aspectRatioDesktop}`,
            props.className
          )}`}
          ref={paralaxImgWrapper}
        >
          <div className="img-to-animate" ref={imgWrapper}>
            <picture data-locator="picture-tag--using-isAdvanced">
              {!error && small && (
                <source
                  data-srcset={`${small} 1x, ${small2x} 2x`}
                  media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
                />
              )}
              {!error && medium && (
                <source
                  data-srcset={`${medium} 1x, ${medium2x} 2x`}
                  media={`(max-width: ${viewportSizes.mediumMaxWidth -
                    0.01}px)`}
                />
              )}
              {!error && large && (
                <source
                  data-srcset={`${large} 1x, ${large2x} 2x`}
                  media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
                />
              )}
              {!error && xLarge && (
                <source
                  data-srcset={`${xLarge} 1x, ${xLarge2x} 2x`}
                  media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
                />
              )}
              <img
                className={clsx(
                  classes.img,
                  'lazyload',
                  error && classes.fallback,
                  classes.fallbackBackground
                )}
                alt={alt || alternateAltText}
                data-src={src}
                onError={() => setError(true)}
                data-locator="img-tag-using-isAdvanced"
                onClick={props.onClick}
              />
            </picture>
          </div>
        </div>
      )}

      {isAdvanced && disableLazyLoad && !noRetina && (
        <div
          className={`img-to-animate-wrapper ${classes.paralaxImgWrapper} ${
            hasParalax ? 'has-paralax' : ''
          } ${clsx(
            classes.root,
            aspectRatioMobile &&
              classes[`aspectRatio--m__${aspectRatioMobile}`],
            aspectRatioDesktop &&
              classes[`aspectRatio--d__${aspectRatioDesktop}`],
            props.className
          )}`}
          ref={paralaxImgWrapper}
        >
          <div className="img-to-animate" ref={imgWrapper}>
            <picture data-locator="picture-tag--using-isAdvanced">
              {!error && small && (
                <source
                  srcSet={`${small} 1x, ${small2x} 2x`}
                  media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
                />
              )}
              {!error && medium && (
                <source
                  srcSet={`${medium} 1x, ${medium2x} 2x`}
                  media={`(max-width: ${viewportSizes.mediumMaxWidth -
                    0.01}px)`}
                />
              )}
              {!error && large && (
                <source
                  srcSet={`${large} 1x, ${large2x} 2x`}
                  media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
                />
              )}
              {!error && xLarge && (
                <source
                  srcSet={`${xLarge} 1x, ${xLarge2x} 2x`}
                  media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
                />
              )}
              <img
                className={clsx(
                  classes.img,
                  error && classes.fallback,
                  classes.fallbackBackground
                )}
                alt={alt || alternateAltText}
                src={src}
                onError={() => setError(true)}
                data-locator="img-tag-using-isAdvanced"
                onClick={props.onClick}
              />
            </picture>
          </div>
        </div>
      )}

      {isAdvanced && disableLazyLoad && noRetina && (
        <div
          className={`img-to-animate-wrapper ${classes.paralaxImgWrapper} ${
            hasParalax ? 'has-paralax' : ''
          } ${clsx(
            classes.root,
            aspectRatioMobile &&
              classes[`aspectRatio--m__${aspectRatioMobile}`],
            aspectRatioDesktop &&
              classes[`aspectRatio--d__${aspectRatioDesktop}`],
            props.className
          )}`}
          ref={paralaxImgWrapper}
        >
          <div className="img-to-animate" ref={imgWrapper}>
            <picture data-locator="picture-tag--using-isAdvanced-noRetina">
              {!error && small && (
                <source
                  srcSet={`${small} 1x`}
                  media={`(max-width: ${viewportSizes.smallMaxWidth - 0.01}px)`}
                />
              )}
              {!error && medium && (
                <source
                  srcSet={`${medium} 1x`}
                  media={`(max-width: ${viewportSizes.mediumMaxWidth -
                    0.01}px)`}
                />
              )}
              {!error && large && (
                <source
                  srcSet={`${large} 1x`}
                  media={`(max-width: ${viewportSizes.largeMaxWidth}px)`}
                />
              )}
              {!error && xLarge && (
                <source
                  srcSet={`${xLarge} 1x`}
                  media={`(min-width: ${viewportSizes.largeMaxWidth + 0.01}px)`}
                />
              )}
              <img
                className={clsx(
                  classes.img,
                  error && classes.fallback,
                  classes.fallbackBackground
                )}
                alt={alt || alternateAltText}
                src={src}
                onError={() => setError(true)}
                data-locator="img-tag-using-isAdvanced"
                onClick={props.onClick}
              />
            </picture>
          </div>
        </div>
      )}
    </>
  );
};

Picture.propTypes = {
  alternateAltText: PropTypes.string,
};

Picture.defaultProps = {
  onClick: () => {},
};

export default Picture;
