export const mixins = (isRtl = false) => ({
  right: unit =>
    isRtl
      ? {
          left: unit,
        }
      : {
          right: unit,
        },

  left: unit =>
    isRtl
      ? {
          right: unit,
        }
      : {
          left: unit,
        },

  positionLR: (leftUnit, rightUnit) =>
    isRtl
      ? {
          left: rightUnit,
          right: leftUnit,
        }
      : {
          left: leftUnit,
          right: rightUnit,
        },

  borderLeft: unit =>
    isRtl
      ? {
          borderRight: unit,
        }
      : {
          borderLeft: unit,
        },
  borderRight: unit =>
    isRtl
      ? {
          borderLeft: unit,
        }
      : {
          borderRight: unit,
        },

  marginRight: unit =>
    isRtl
      ? {
          marginLeft: unit,
        }
      : {
          marginRight: unit,
        },

  marginLeft: unit =>
    isRtl
      ? {
          marginRight: unit,
        }
      : {
          marginLeft: unit,
        },

  marginLR: (left, right) =>
    isRtl
      ? {
          marginLeft: right,
          marginRight: left,
        }
      : {
          marginLeft: left,
          marginRight: right,
        },

  paddingLeft: unit =>
    isRtl
      ? {
          paddingRight: unit,
        }
      : {
          paddingLeft: unit,
        },
  paddingRight: unit =>
    isRtl
      ? {
          paddingLeft: unit,
        }
      : {
          paddingRight: unit,
        },

  paddingLR: (left, right) =>
    isRtl
      ? {
          paddingLeft: right,
          paddingRight: left,
        }
      : {
          paddingLeft: left,
          paddingRight: right,
        },

  textLeft: () => (isRtl ? 'right' : 'left'),
  textRight: () => (isRtl ? 'left' : 'right'),

  flexDirection: () => (isRtl ? 'row-reverse' : 'row'),
  rotate180: () => (isRtl ? 'rotate(0)' : 'rotate(180deg)'),
  rotate0: () => (isRtl ? 'rotate(180deg)' : 'rotate(0)'),
  translateX: angle => (isRtl ? -angle : angle),

  // Style Guide Colors
  // recommended opacity weight is 100, 200 ...to 900
  White: (opacityWeight = 1000) => `rgba(255,255,255, ${opacityWeight / 1000})`,
  Black: (opacityWeight = 1000) => `rgba(0, 0, 0, ${opacityWeight / 1000})`,
  Grey: (opacityWeight = 1000) => `rgba(86, 90, 92, ${opacityWeight / 1000})`,
  AbuDhabiRed: (opacityWeight = 1000) =>
    `rgba(194, 0, 47, ${opacityWeight / 1000})`,
  milkWhite: (opacityWeight = 1000) =>
    `rgba(218, 215, 203, ${opacityWeight / 1000})`,
  neroBlack: (opacityWeight = 1000) =>
    `rgba(25, 25, 25, ${opacityWeight / 1000})`,
  ADCalendar: (opacityWeight = 1000) =>
    `rgba(105, 15, 168, ${opacityWeight / 1000})`,
  SolidGrey: (opacityWeight = 1000) =>
    `rgba(120, 123, 125, ${opacityWeight / 1000})`,
});
