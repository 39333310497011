import { mixins } from './mixins';
import { common } from './palette';

const colorMixins = mixins();

export const borders = {
  divider: `1px solid ${colorMixins.Black(200)}`,
  collageBorder: `40px solid ${colorMixins.White()}`,
  tab: `4px solid ${colorMixins.AbuDhabiRed()}`,
  fieldBorder: '1px solid rgb(86, 90, 92)',
  dropDownBorder: `1px solid ${colorMixins.Grey(400)}`,
  redBorder: '1px solid rgb(194, 0, 47)',
  formCollectionMobile: `32px solid ${common.Sunrise}`,
  formCollectionDesktop: `46px solid ${common.Sunrise}`,
};
