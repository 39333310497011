import { combineReducers } from 'redux';
import SmartListReducer from '../global/containers/common/SmartList/redux/reducer';
import LiveWeatherInfoComponentReducer from '../global/components/organism/LiveWeatherInfo/redux/reducer';
import FiltersReducer from '../global/containers/common/Filters/redux/filtersReducer';
import SelectedFiltersReducer from '../global/containers/common/Filters/redux/selectedFilterReducer';
import FilteredDataReducer from '../global/containers/common/Filters/redux/filteredReducer';
import ClearAllDataReducer from '../global/containers/common/Filters/redux/clearAllReducer';
import EventListReducer from '../global/containers/common/CollageType1/redux/reducer';
import SimilarSearchReducer from '../global/containers/common/SimilarSearch/redux/reducer';
import SearchDataReducer from '../global/containers/common/Search/redux/searchReducer';
import SearchTermDataReducer from '../global/containers/common/GlobalSearchSubLayout/redux/searchTermReducer';

export default combineReducers({
  LiveWeatherInfoComponentReducer: LiveWeatherInfoComponentReducer,
  EventListReducer: EventListReducer,
  FiltersReducer: FiltersReducer,
  SelectedFiltersReducer: SelectedFiltersReducer,
  FilteredDataReducer: FilteredDataReducer,
  SmartListReducer: SmartListReducer,
  SimilarSearchReducer: SimilarSearchReducer,
  SearchDataReducer: SearchDataReducer,
  SearchTermDataReducer: SearchTermDataReducer,
  ClearAllDataReducer: ClearAllDataReducer,
});
