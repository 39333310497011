import FallbackBackgroundLogo from '../../../../assets/svg/White@1.5x.svg';

export const styles = theme => {
  let customStyle = {
    paralaxImgWrapper: {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '& .img-to-animate': {
        width: '100%',
        height: '100%',
      },
      '&.has-paralax': {
        '& .img-to-animate': {
          position: 'absolute',
          backfaceVisibility: 'hidden',
          top: 0,
          left: 0,
          display: 'block',
          width: '120%',
          height: '120%',
        },
      },
    },
    root: {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '100%',
    },
    fallbackBackground: {
      backgroundColor: '#dfe5e8',
      backgroundImage: `url(${FallbackBackgroundLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: '20%',
    },
    ...[
      { size: 'm', bp: 'md', dir: 'down' },
      { size: 't', bp: 'md', dir: 'up' },
      { size: 'd', bp: 'lg', dir: 'up' },
    ].reduce(
      (r, viewport) => ({
        ...r,
        ...[
          { ratio: '', paddingTop: '' }, //first line is not getting pick due to some unknown reason that is why added dummy line, will be removed later
          { ratio: '1x1', paddingTop: '100%' },
          { ratio: '2x1', paddingTop: '50%' },
          { ratio: '3x1', paddingTop: '33.333%' },
          { ratio: '16x9', paddingTop: '56.25%' },
          { ratio: '16x9-m', paddingTop: '56.25%' },
          { ratio: '4x3', paddingTop: '75%' },
          { ratio: '4x5', paddingTop: '125%' },
          { ratio: '3x2', paddingTop: '66.67%' },
          { ratio: '3x2-m', paddingTop: '66.67%' },
          { ratio: '3x2-card', paddingTop: '66.67%' },
          { ratio: '16x6', paddingTop: '37.5%' },
        ].reduce((rr, ratio) => ({
          ...rr,
          ...{
            [`aspectRatio--${viewport.size}__${ratio.ratio}`]: {
              [theme.breakpoints[viewport.dir](viewport.bp)]: {
                paddingTop: ratio.paddingTop,
                '& img': {
                  position: 'absolute',
                  top: '0',
                  left: '0',
                },
              },
            },
          },
        })),
      }),
      {}
    ),
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      overflow: 'hidden',

      '&.noRendition': {
        height: 'inherit',
      },
    },
    fallback: {
      objectFit: 'scale-down',
    },
  };
  return customStyle;
};
