const baseFont = ' Arial, sans-serif';

export const fontFamilyEN = {
  primaryLight: ['Lato-Light', baseFont].join(','),
  primaryRegular: ['Lato-Regular', baseFont].join(','),
  primaryBold: ['Lato-Bold', baseFont].join(','),
  primaryBlack: ['Lato-Black', baseFont].join(','),
  secondaryRegular: ['Tinos-Regular', baseFont].join(','),
  secondaryBold: ['Tinos-Bold', baseFont].join(','),
};

export const fontFamilyAR = {
  primaryRegular: ['NotoKufiArabic', baseFont].join(','),
  primaryBold: ['NotoKufiArabic-Bold', baseFont].join(','),
};

export const fontFamilyHE = {
  primaryLight: ['Heebo-Light', baseFont].join(','),
  primaryRegular: ['Heebo-Regular', baseFont].join(','),
  primaryBold: ['Heebo-Bold', baseFont].join(','),
};

export const fontFamilyZH = {
  primaryLight: ['NotoSansSc-Light', baseFont].join(','),
  primaryRegular: ['NotoSansSc-Regular', baseFont].join(','),
  primaryBold: ['NotoSansSc-Bold', baseFont].join(','),
  primaryBlack: ['NotoSansSc-Black', baseFont].join(','),
  secondaryRegular: ['NotoSerifSc-Regular', baseFont].join(','),
  secondarySemiBold: ['NotoSerifSc-SemiBold', baseFont].join(','),
  secondaryBold: ['NotoSerifSc-Bold', baseFont].join(','),
  secondaryBlack: ['NotoSerifSc-Black', baseFont].join(','),
};

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
};
