import React from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import { Route, Switch } from 'react-router-dom';
import componentFactory from './temp/componentFactory';
import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import store, { sagaMiddleware } from './store';
import rootSaga from './store/rootSaga';
import { dataFetcher } from './dataFetcher';
import { makeStyles } from '@material-ui/core/styles';
import { canUseDOM } from './global/utils/canUseDOM';
// import theme from './styles/themes';

sagaMiddleware.run(rootSaga);

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

const styles = theme => {
  return theme.overrides;
};

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, pageId, appInsights, appConfig }) => {
  makeStyles(styles)();
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const createPath = pathName => {
    if (pathName.indexOf('/') > -1) {
      let pathItem = pathName.split('/');
      var pathLength = pathItem.length;
      if (pathLength > 2) {
        if (pathItem[2].length > 0) {
          //path  -> /en-gb/where-to-go
          pathItem.splice(1, 1);
        } else {
          //path  -> /en-gb/
          pathItem[1] = 'Home';
          pathItem.splice(2, 1);
        }
      } else {
        //  path ->/en
        pathItem[pathLength - 1] = 'Home';
      }
      return pathItem.join('/');
    } else {
      //path  -> 'not set'
      return pathName;
    }
  };

  //pushing the dataLayer values with correct path
  React.useEffect(() => {
    if (path && canUseDOM && window.dataLayer && window.dataLayer.length) {
      window.dataLayer[0].pageName = createPath(path);
      window.dataLayer[0].previousPageName = createPath(
        window.dataLayer[0].previousPageName
      );
    }
  }, [path]);

  // for adding Sitecore Page Level Analytics
  React.useEffect(() => {
    if (pageId && path) {
      const trackingApiOptions = {
        host: window ? window.location.origin : appConfig.sitecoreApiHost,
        querystringParams: {
          sc_apikey: appConfig.sitecoreApiKey,
        },
        fetcher: dataFetcher,
      };
      trackingApi
        .trackEvent([{ pageId, url: path }], trackingApiOptions)
        .catch(error => console.log(error));
    }
  }, [pageId, path]);
  const routeRenderFunction = props => (
    <RouteHandler
      route={props}
      appInsights={appInsights}
      appConfig={appConfig}
    />
  );
  return (
    <SitecoreContext
      componentFactory={componentFactory}
      contextFactory={SitecoreContextFactory}
    >
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <Router location={path} context={{}}>
          <Switch>
            {routePatterns.map(routePattern => (
              <Route
                key={routePattern}
                path={routePattern}
                render={routeRenderFunction}
              />
            ))}
          </Switch>
        </Router>
        {/* <MyFirstComponent {...data}/> */}
        {/* </PersistGate> */}
      </Provider>
    </SitecoreContext>
  );
};

export default AppRoot;
