/* 
  This file defines color palette
*/

import { mixins } from './mixins';

//Base Colors
const AbuDhabiRed = 'rgb(194, 0, 47)'; //#C2002F
const ADCalendar = 'rgb(105, 15, 168)'; //#690FA8

//Extended Colors
const CalligraphicGrey = 'rgb(86, 90, 92)'; //#565a5c
const GlitteringSand = '#b4a7a2';
const DesrtSunset = 'rgb(162, 80, 34)'; //#A25022
const SandDune = 'rgb(202, 155, 74)'; //#CA9B4A
const Sunrise = 'rgb(239, 189, 71)'; //#EFBD47
const Sandstone = 'rgb(202, 192, 182)'; //#CAC0B6
const DesertDusk = 'rgb(233, 211, 215)'; //#E9D3D7
const DateBrown = 'rgb(82, 45, 36)'; //#522D24
const PalmForest = 'rgb(62, 69, 25)'; //#3E4519
const OasisGreen = 'rgb(135, 150, 55)'; //#879637
const SpringGreen = 'rgb(199, 210, 138)'; //#C7D28A
const WovenPalm = 'rgb(214, 212, 174)'; //#D6D4AE
const SeaPort = 'rgb(0, 65, 101)'; //#004165
const Mangroves = 'rgb(21, 101, 112)'; //#156570
const MarineBlue = 'rgb(0, 102, 161)'; //#0066A1
const ArabianGulf = 'rgb(84, 130, 171)'; //#5482AB
const TurquoiseShore = 'rgb(183, 206, 196)'; //#B7CEC4
const ContemporaryChrome = 'rgb(94, 106, 113)'; //#5E6A71
const IlluminatedSkyline = 'rgb(134, 131, 164)'; //#8683A4
const EnergyBlue = 'rgb(125, 154, 170)'; //#7D9AAA
const CityscapeGrey = 'rgb(173, 175, 175)'; //#ADAFAF
const QuiteMarble = 'rgb(218, 215, 203)'; //#DAD7CB
const MysticGrey = 'rgb(221, 223, 222)'; //#DDDFDE
const LightGrey = 'rgb(170 170 170)'; //#AAAAAA
const Grey1 = 'rgb(120, 123, 125)'; // #787B7D
const SeashellWhite = 'rgb(250 249 245)'; //#FAF9F5
const RedRibbon = 'rgb(231, 15, 67)'; // #E70F43
const TripAdvisorThemeColor = 'rgb(0, 170, 108)'; // #00AA6C

//Other Colors
const RaisinBlack = 'rgba(38,38,38,1)'; // this is used for gradient creation in header
const MostlyBlack = 'rgba(2, 2, 2, 0)'; // this is used for gradient creation in header
const LightGray = '#fcfcfc'; // This is used for bg color privacy policy component
const ErrorRed = 'rgb(255, 12, 12)';
const black3 = '#f7f7f7';
const DisabledGrey = '#dddede';
const BlackFadded = 'rgba(0, 0, 0, 0.8)';
const Black4 = 'rgb(51, 51, 51)'; // #333333
const White = 'rgb(255, 255, 255)'; // #FFFFFF

// This is default ellipsis after two lines
// but you can pass line in params as per your need
const ellipsis = (line = 2) => ({
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
  '-webkit-line-clamp': line,
  'line-clamp': line,
  overflow: 'hidden',
});

export const common = {
  ellipsis: ellipsis,
  raisinBlack: RaisinBlack,
  mostlyBlack: MostlyBlack,
  BlackFadded,
  Black4,
  White,
  AbuDhabiRed,
  ADCalendar,
  CalligraphicGrey,
  GlitteringSand,
  SeaPort,
  SandDune,
  DesrtSunset,
  Sunrise,
  Sandstone,
  DesertDusk,
  DateBrown,
  PalmForest,
  OasisGreen,
  SpringGreen,
  WovenPalm,
  Mangroves,
  MarineBlue,
  ArabianGulf,
  TurquoiseShore,
  ContemporaryChrome,
  IlluminatedSkyline,
  EnergyBlue,
  CityscapeGrey,
  QuiteMarble,
  MysticGrey,
  LightGrey,
  Grey1,
  SeashellWhite,
  RedRibbon,
  TripAdvisorThemeColor,
};

export const background = {
  default: black3,
  transparent: 'transparent',
  yellow10: 'rgb(253, 248, 236)',
  seaport10: '#f0eeee',
  lightGray: LightGray,
  disabledGrey: DisabledGrey,
};

export const border = {
  divider: mixins().Grey(100),
  error: ErrorRed,
};

export const text = {
  primary: mixins().Black(),
  secondary: mixins().AbuDhabiRed(),
  tertiary: 'rgb(112, 108, 108)',
  tertiary1: 'rgb(120, 123, 125)',
  darkGrey: CalligraphicGrey,
  disabled: mixins().Black(200),
  tabDefaultText: mixins().Black(500),
  error: ErrorRed,
};

export const action = {
  hover: mixins().Black(40),
  hoverOpacity: '0.5',
  disabled: mixins().Black(200),
  disabledBackground: black3,
  disabledOpacity: '0.3',
};

export const secondary = {
  main: mixins().White(),
  contrastText: mixins().Black(),
};

export const primary = {
  main: mixins().Black(),
  contrastText: mixins().White(),
};

export const palette = {
  primary,
  secondary,
  text,
  common,
  action,
  background,
  border,
};
