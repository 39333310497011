import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root.MuiAlert-root': {
      padding: `${pxToRem(15)} ${pxToRem(16)}`,
      backgroundColor: theme.mixins.White(),
      '& > .MuiAlert-icon': {
        '& img': {
          borderRadius: pxToRem(6),
          width: pxToRem(24),
          height: pxToRem(24),
        },
      },
    },
  },
  message: {
    [theme.breakpoints.down('md')]: {
      fontFamily: theme.typography.fontFamily.primaryRegular,
    },
  },
}));

export default useStyles;
