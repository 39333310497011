//import { common } from './palette';  #TODO
import { fontFamilyZH as fontFamily } from './font';
import { desktopMedia, pxToRem } from './constants';

export const variantItemsZH = {
  h1: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: pxToRem(40),
    lineHeight: pxToRem(48),
    letterSpacing: '0',
    marginLeft: pxToRem(-8),
    [desktopMedia]: {
      fontSize: pxToRem(120),
      lineHeight: pxToRem(140),
      marginLeft: pxToRem(-16),
    },
  },
  h2: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(40),
    letterSpacing: '0',
    marginLeft: pxToRem(-8),
    [desktopMedia]: {
      fontSize: pxToRem(72),
      lineHeight: pxToRem(80),
      marginLeft: pxToRem(-16),
    },
  },
  h3: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(64),
      lineHeight: pxToRem(72),
    },
  },
  h4: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBlack,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(32),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(48),
      lineHeight: pxToRem(56),
    },
  },
  h5: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(40),
    },
  },
  h5Bold: {
    color: 'inherit',
    fontFamily: fontFamily.secondaryBold,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(40),
    },
  },
  body1: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
    },
  },
  body1Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    [desktopMedia]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
    },
  },
  body2: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  },
  body2Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    [desktopMedia]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  },
  body3: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16),
    },
  },
  body3Bold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    letterSpacing: '0',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    [desktopMedia]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16),
    },
  },
  labelLight: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: '0',
  },
  labelBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: '0',
  },
  labelBlack: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBlack,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: '0.5',
  },
  labelRegular: {
    color: 'inherit',
    fontFamily: fontFamily.primaryRegular,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    letterSpacing: '0',
  },
  subTitle: {
    color: 'inherit',
    fontFamily: fontFamily.primaryLight,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(18),
      lineHeight: pxToRem(24),
    },
  },
  subTitleBold: {
    color: 'inherit',
    fontFamily: fontFamily.primaryBold,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
    [desktopMedia]: {
      fontSize: pxToRem(18),
      lineHeight: pxToRem(24),
    },
  },
  cardTitle1: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    letterSpacing: '0',
  },
  cardTitle2: {
    color: 'inherit',
    fontFamily: fontFamily.secondarySemiBold,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    letterSpacing: '0',
  },
  gutterBottom: {
    marginBottom: pxToRem(24),
  },
};
