import { desktopMedia, pxToRem } from './constants';

const shape = {
  componentSpacing: {
    marginBottom: pxToRem(48),
    [desktopMedia]: {
      marginBottom: pxToRem(88),
    },
  },
  fullBleedingBox: {
    left: '50vw',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    position: 'relative',
    right: '50vw',
    width: '100%',
    paddingTop: pxToRem(48),
    paddingBottom: pxToRem(48),
    [desktopMedia]: {
      paddingTop: pxToRem(88),
      paddingBottom: pxToRem(88),
    },
  },
};

export default shape;
