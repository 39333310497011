import { makeStyles } from '@material-ui/core/styles';
import { replaceKeysLabelText } from '../../../../utils/utility';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      '&:not(.Mui-disabled):hover': {
        '& .MuiButton-endIcon, & .MuiButton-startIcon': {
          overflow: 'hidden',

          '& .MuiIcon-root': {
            animationName: '$arrowMove',
            animationDuration: '.5s',
            animationIterationCount: '1',
          },
        },
      },
    },
  },
  primary: {
    fontFamily: theme.typography.fontFamily.primaryRegular,
    [theme.breakpoints.up('lg')]: {
      lineHeight: pxToRem(32),
      '&.MuiButton-sizeSmall': {
        fontFamily: theme.typography.fontFamily.primaryBold,
        lineHeight: pxToRem(24),
      },
    },
    '& .MuiButton-label': {
      textDecoration: 'none',
      transition: 'text-decoration 0.3s ease-in-out',
    },

    '&:hover': {
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
    },

    '& .MuiButton-endIcon': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.AbuDhabiRed(),
    },
    '&:hover .MuiButton-endIcon': {
      backgroundColor: theme.mixins.Black(),
    },
  },
  secondary: {
    [theme.breakpoints.up('lg')]: {
      lineHeight: pxToRem(32),
      '&.MuiButton-sizeSmall': {
        lineHeight: pxToRem(24),
      },
    },
    '& .MuiButton-label': {
      color: props =>
        props.themeColor
          ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
          : theme.mixins.AbuDhabiRed(),
    },
    '&:hover': {
      '& .MuiButton-startIcon': {
        backgroundColor: props =>
          props.themeColor
            ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
            : theme.mixins.AbuDhabiRed(),
      },
      '& .MuiButton-label': {
        color: props =>
          props.themeColor
            ? replaceKeysLabelText({ value: props.themeColor }, ['0.5']).value
            : theme.mixins.AbuDhabiRed(500),
      },
    },
  },
  form: {
    backgroundColor: props =>
      props.themeColor
        ? replaceKeysLabelText({ value: props.themeColor }, ['1']).value
        : theme.mixins.AbuDhabiRed(),
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.mixins.Black(),
    },
  },
  '@keyframes arrowMove': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: `translateX(${theme.mixins.translateX(130)}%)` },
    '51%': { transform: `translateX(${theme.mixins.translateX(-130)}%)` },
    '100%': { transform: 'translateX(0)' },
  },
}));

export default useStyles;
