import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,

    '&:hover': {
      color: props => props.hoverColor,
      textDecoration: 'none',
    },
    '&.isDisabled': {
      color: props => props.disableColor,
      pointerEvents: 'none',
    },
  },
  buttonLink: {
    color: theme.mixins.White(),
    backgroundColor: theme.mixins.AbuDhabiRed(),
    borderRadius: '1.75rem',
    padding: '0.75rem 1.5rem',
    '&:hover': {
      backgroundColor: theme.mixins.AbuDhabiRed(500),
      overflow: 'hidden',

      '& .MuiIcon-root': {
        animationName: '$arrowMove',
        animationDuration: '.5s',
        animationIterationCount: '1',
      },
    },
    display: 'flex',
    '& .MuiIcon-root': {
      ...theme.mixins.marginLeft(theme.spacing(2)),
    },
  },
  '@keyframes arrowMove': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: `translateX(${theme.mixins.translateX(130)}%)` },
    '51%': { transform: `translateX(${theme.mixins.translateX(-130)}%)` },
    '100%': { transform: 'translateX(0)' },
  },
}));

export default useStyles;
