export const v1 = 123;
export const v2 = 'https://website/api';
export const v3 = 'https:website.com/search/en/';

// Need to check language keys with sitecore whenever any more language get added in website as of today(en, ar-AE, ch-CN) are correct
export const languageIdList = {
  en: '1',
  'en-GB': '1',
  ar: '2',
  'ar-AE': '2',
  fr: '3',
  de: '4',
  ru: '5',
  jap: '6',
  it: '7',
  zh: '8',
  'zh-CN': '8',
  por: '9',
  nld: '10',
};
export const LOCATION_ID = '1'; // currently fixed location id i.e ABU DHABI for live weather api
export const ISMOBILE = false;
export const HOME_PAGE_ROUTE_NAME = 'Home';
export const SOCIAL_SHARE_SCRIPT_URL =
  '//s7.addthis.com/js/300/addthis_widget.js#pubid=';

export const EVENT_TYPE = 'event';
export const ATTRIBUTE_TYPE = 'attribute';
export const EVENT_LISTING_TEMPLATE_ID = 'dc24d618-8d10-47d6-be80-cf36ceffec65';
export const TAG_TEXT = 'tag';
export const LISTING_TYPE = 'listing';
export const COMMON_TYPE = 'common';
export const TAGS = 'Tags';
export const SEARCH_RESULT_TAGS = 'searchResultTags';
export const EVENTS_THEME_COLOR = 'rgba(105, 15, 168, {0})';
export const EVENTS_TAG_THEME_COLOR = 'rgb(239, 81, 87)';
export const SIMILAR_SEARCH_EVENT_TAG_BACKGROUND_COLOR =
  'rgba(239, 81, 87, 0.1)';
export const SIMILAR_SEARCH_EVENT_TAG_COLOR = 'rgb(239, 81, 87)';
export const LISTING_THEME_COLOR = 'rgba(194, 0, 47, {0})';
export const CLOSE_ICON_TEXT = 'Close';
export const INFO_ICON_TEXT = 'More Info';
export const SIMILAR_EVENT_DETAILS_PAYLOAD_KEY = 'similarevents';
export const LABEL_404_PAGE = '404Page';
export const ARABIC_LANG_LAYOUT = 'ar';
export const ARABIC_LANG_API_PAYLOAD = 'ar';
export const INSIDE_CHINA_COUNTRY_CODE = 'cn';
export const OUTSIDE_CHINA_COUNTRY_CODE = 'ae';
export const LANGUAGE_CODE = {
  ENGLISH: 'en',
  ARABIC: 'ar',
  CHINESE: 'zh',
  DEFAULT: 'en',
  HEBREW: 'he',
};
export const YOUKU_PLAYER_API_CLIENT_KEY = '0edbfd2e4fc91b72';
export const GOOGLE_DIRECTION_URL = 'https://www.google.ae/maps/dir/';
export const BAIDU_DIRECTION_URL = 'https://api.map.baidu.com/direction';
export const DOMAIN_SUFFIX = {
  CHINA: '.cn',
  ARABIC: '.ae',
};
export const HIGHLIGHTS_KEY = 'Highlights';
export const GOOGLE_MAP_API = 'https://maps.googleapis.com/maps/api/js';
export const REGION_KEY = 'region';
export const BAIDU_MAP_API_URL =
  'https://api.map.baidu.com/api?v=3.0&ak={apiKey}&callback=initBaiduMap';
export const TICKET_COMPONENT_ID = 'ticket123'; // Its alphanumeric to avoid any conflict in future
export const TICKETED_EVENT_TEXT = 'ticketed';
export const MARKETING_MESSAGE_INACTIVE_TILL =
  'MARKETING_MESSAGE_INACTIVE_TILL';

export const availableTemperatures = ['C', 'F'];
export const defaultTemperature = 'C';
export const BAIDU_API_KEY = 'baiduApiKey';
export const GOOGLE_API_KEY = 'googleApiKey';
