export const setSessionStorage = (key, value) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
};

export const getSessionStorage = key => {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
};
