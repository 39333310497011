import React, { useLayoutEffect } from 'react';
import { canUseDOM } from './global/utils/canUseDOM';
import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
// import Splash from '../';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import './assets/app.css';
import DownloadWidget from './global/components/atoms/DownloadWidget';
import OpenGraphMetaInfo from './global/components/atoms/OpenGraphMetaInfo';
import { get } from 'lodash';
// import { get } from 'lodash';
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes

const Layout = ({ route, context, appInsights, appConfig }) => {
  //params context, config

  useLayoutEffect(() => {
    if (canUseDOM && window.pageYOffset !== 0) {
      window.scroll({
        top: '0',
        left: '0',
      });
    }
  });

  return (
    <React.Fragment>
      <OpenGraphMetaInfo
        route={route}
        appConfig={appConfig}
        context={context}
      />
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* <Splash context={context} config={config} /> */}
      {/* <Navigation /> */}
      {get(context, 'VADStaticRoute.sitecore.route') && (
        <Placeholder
          name="vad-main"
          rendering={get(context, 'VADStaticRoute.sitecore.route')}
          appInsights={appInsights}
          appConfig={appConfig}
        />
      )}
      <div role="banner">
        <DownloadWidget sitecoreContext={context} appConfig={appConfig} />
        <Placeholder
          name="vad-header"
          rendering={route}
          appInsights={appInsights}
          appConfig={appConfig}
        />
      </div>
      {/* root placeholder for the app, which we add components to using route data */}
      <div role="main">
        <Placeholder
          name="vad-main"
          rendering={route}
          appInsights={appInsights}
          appConfig={appConfig}
        />
      </div>
      <Placeholder
        name="vad-footer"
        rendering={route}
        appInsights={appInsights}
        appConfig={appConfig}
      />
      <div id="modalPortal"></div>
    </React.Fragment>
  );
};

export default Layout;
