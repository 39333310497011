/* eslint-disable max-len */
import React from 'react';

export const DollarSign = (props) => {
  return (
    <svg
      width="6.0"
      height="11.22"
      viewBox="0 0 6.1 11.2"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      {!props.titleDisabled ? (
        <title>$</title>
      ) : ''}
      <path fill="#FFFFFF" d="M3.4,8.7c0.4-0.1,0.7-0.2,1-0.4c0.2-0.2,0.3-0.5,0.3-0.9c0-0.2,0-0.3-0.1-0.4C4.5,6.9,4.5,6.8,4.4,6.7
    C4.3,6.6,4.2,6.5,4,6.5C3.9,6.4,3.7,6.3,3.6,6.3L3.4,8.7z M3,2.4c-0.2,0-0.4,0.1-0.6,0.1C2.3,2.6,2.1,2.7,2,2.8
    C1.9,2.9,1.9,3,1.8,3.1c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.3,0.1,0.4C1.9,4,2,4.1,2.1,4.2c0.1,0.1,0.2,0.2,0.3,0.2
    c0.1,0.1,0.3,0.1,0.4,0.2L3,2.4z M3.7,4.9C4,5,4.3,5.1,4.6,5.2c0.3,0.1,0.5,0.2,0.8,0.4C5.6,5.8,5.7,6,5.9,6.2
    C6,6.5,6.1,6.8,6.1,7.2c0,0.4-0.1,0.7-0.2,1.1C5.8,8.5,5.6,8.8,5.4,9.1C5.1,9.3,4.8,9.5,4.5,9.7C4.1,9.8,3.7,9.9,3.3,10l-0.1,0.9
    c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H2.3l0.1-1.3C1.9,9.9,1.5,9.7,1.1,9.6C0.6,9.4,0.3,9.1,0,8.8l0.5-0.7
    c0-0.1,0.1-0.1,0.2-0.1C0.7,8,0.8,8,0.8,8C0.9,8,1,8,1.1,8.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.4,0.2
    c0.2,0.1,0.4,0.1,0.6,0.2L2.7,6C2.4,5.9,2.1,5.8,1.8,5.7C1.6,5.6,1.3,5.5,1.1,5.3C0.9,5.1,0.7,4.9,0.6,4.6C0.4,4.4,0.4,4,0.4,3.6
    c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.5-0.4,0.9-0.6s0.7-0.2,1.2-0.3l0.1-0.8c0-0.1,0-0.2,0.1-0.3S3.5,0,3.6,0
    h0.5L4,1.2c0.4,0.1,0.8,0.2,1.1,0.4c0.3,0.2,0.6,0.4,0.8,0.6L5.6,2.7C5.5,2.7,5.5,2.8,5.4,2.8c-0.1,0-0.1,0.1-0.2,0.1
    c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1C4.6,2.7,4.5,2.6,4.3,2.5C4.2,2.5,4.1,2.4,3.9,2.4L3.7,4.9z"/>
    </svg>
  )
}

export const ViewEye = (props) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.87757 3.1759C6.85758 3.17575 6.77887 3.17575 6.75874 3.1759C6.02183 3.18171 5.40816 3.78301 5.40328 4.50411C5.3984 5.22179 6.0081 5.83382 6.74272 5.84887C6.7682 5.84932 6.86811 5.84932 6.89358 5.84887C7.62836 5.83382 8.2379 5.22179 8.23318 4.50411C8.22814 3.78301 7.61448 3.18171 6.87757 3.1759ZM6.85522 7.6417C6.84271 7.64155 6.83066 7.63977 6.81815 7.63962C6.80564 7.63977 6.79359 7.64155 6.78109 7.6417C5.01957 7.64766 3.57458 6.24479 3.56924 4.52316C3.56405 2.79855 4.99593 1.38837 6.7579 1.38301C6.77819 1.38301 6.79787 1.38554 6.81815 1.38584C6.83844 1.38554 6.85812 1.38301 6.87825 1.38301C8.64038 1.38837 10.0724 2.79855 10.0671 4.52316C10.0617 6.24479 8.61689 7.64766 6.85522 7.6417ZM13.5584 4.357C13.107 3.77225 11.8696 2.20798 11.085 1.59581C9.76428 0.565631 8.32371 -0.0297046 6.81815 0.00114258C5.31275 -0.0297046 3.87203 0.565631 2.5515 1.59581C1.76669 2.20798 0.529298 3.77225 0.0779371 4.357C-0.0248738 4.49022 -0.0259416 4.67277 0.0745813 4.80734C0.212934 4.99272 0.400556 5.24531 0.494214 5.37749C2.25649 7.86404 4.54487 9.06768 6.81815 9.06932C9.09143 9.06768 11.38 7.86404 13.1422 5.37749C13.2358 5.24531 13.4235 4.99272 13.5617 4.80734C13.6624 4.67277 13.6612 4.49022 13.5584 4.357Z" fill="white" />
    </svg>
  )
}