import { all, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import { filteredData } from './selectors';
import { updateFilterData } from '../../../../../utils/utility';

/*
 * Get Filters data from solr API
 */

export function* getFiltersInfoSaga(action) {
  yield put(actions.getFiltersInfoStart());
  try {
    const response = yield fetchService({
      url: action.payload.url,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      const data = updateFilterData(
        response.data.data,
        action.payload.sitecoreFilters,
        action.payload.dictionary
      );
      if (data !== null && data !== 'undefined') {
        yield put(
          actions.getFiltersInfoSuccess({
            results: data,
            apiResult: response.data.data,
          })
        );
      } else {
        yield put(actions.getFiltersInfoFail(response.data));
      }
    } else {
      yield put(actions.getFiltersInfoFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getFiltersInfoFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* getFilterdInfoSaga(action) {
  const filteredDataState = yield select(filteredData);
  yield put(actions.getFilterdInfoStart());
  try {
    const response = yield fetchService({
      url: action.payload.apiUrl,
      method: 'POST',
      data: action.payload.payload,
    });
    if (response.status === 200) {
      const { data, success } = response.data;
      if (
        action.payload.isLoadMoreClicked &&
        data.results &&
        data.results.length > 0 &&
        filteredDataState.data.results &&
        filteredDataState.data.results.length > 0
      ) {
        data.results = [...filteredDataState.data.results, ...data.results];
      }
      if (data !== null && data !== 'undefined' && success) {
        yield put(
          actions.getFilterdInfoSuccess({
            results: data,
            payload: action.payload,
          })
        );
      } else {
        yield put(actions.getFilterdInfoFail(response.data));
      }
    } else {
      yield put(actions.getFilterdInfoFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getFilterdInfoFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchFiltersInfo() {
  yield all([takeLatest(actionTypes.GET_FILTERSINFO_DATA, getFiltersInfoSaga)]);
}

export function* watchFilterdInfo() {
  yield all([takeLatest(actionTypes.GET_FILTERD_DATA, getFilterdInfoSaga)]);
}
