export const GET_FILTERSINFO_START = 'GET_FILTERSINFO_START';
export const GET_FILTERSINFO_SUCCESS = 'GET_FILTERSINFO_SUCCESS';
export const GET_FILTERSINFO_FAIL = 'GET_FILTERSINFO_FAIL';
export const GET_FILTERSINFO_DATA = 'GET_FILTERSINFO_DATA';
export const UPDATE_FILTERSINFO_DATA = 'UPDATE_FILTERSINFO_DATA';
export const UPDATE_MULTIPLE_FILTERSINFO_DATA =
  'UPDATE_MULTIPLE_FILTERSINFO_DATA';
export const UPDATE_SELECTED_FILTER_DATA_MOBILE =
  'UPDATE_SELECTED_FILTER_DATA_MOBILE';
export const UPDATE_QUERY_FILTERSINFO_DATA = 'UPDATE_QUERY_FILTERSINFO_DATA';

export const GET_FILTERD_DATA_START = 'GET_FILTERD_DATA_START';
export const GET_FILTERD_DATA_SUCCESS = 'GET_FILTERD_DATA_SUCCESS';
export const GET_FILTERD_DATA_FAIL = 'GET_FILTERD_DATA_FAIL';
export const CLEAR_FILTERD_DATA = 'CLEAR_FILTERD_DATA';
export const GET_FILTERD_DATA = 'GET_FILTERD_DATA';

export const UPDATE_SELECTED_FILTER_DATA = 'UPDATE_SELECTED_FILTER_DATA';
export const CLEAR_SELECTED_FILTER_DATA = 'CLEAR_SELECTED_FILTER_DATA';
export const CLEAR_ALL_CLICKED = 'CLEAR_ALL_CLICKED';
